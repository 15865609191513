import React from 'react';
import styled from 'styled-components';
import { Menu } from "./lib";

const Page = styled.div({
  backgroundColor: '#282C34',
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
});

const Container = styled.div({
  display: "flex",
  maxWidth: 500,
  padding: 16,
  paddingTop: 24,
  paddingBottom: 32,
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center'
})

function App() {
  return (
    <Page>
      <Container>
        <Menu/>
      </Container>
    </Page>
  );
}

export default App;
