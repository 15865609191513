import React from 'react';
import ProjectItem, { IProject } from "./components/ProjectItem";
import styled from "styled-components";

interface Props {}

const projects: IProject[] = require('./projects.json');


const Projects: React.FC<Props> = () => {
  return (
    <div style={{marginTop: 16}}>
      {
        projects.map((project, index) => {
          return (
            <div>
              <ProjectItem project={project} />
              {index !== projects.length - 1 ? <Divider /> : null}
            </div>
          )
        })
      }
    </div>
  );
}

const Divider = styled.div({
  height: 1,
  marginTop: 16,
  marginBottom: 16,
  backgroundColor: 'rgba(242, 242, 242, 0.2)',
})

export default Projects;