import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {NonDriftingInterval} from "../../helpers";

const dd = (d: number) => `${d < 10 ? 0 : ''}${d}`;

const correctTimezone = (ts: number) => {
  // 'Mon, 11 Jul 2022 11:07:27 GMT'
  const UTCDateSplit = new Date(ts).toUTCString().split(' ');
  const UTCTimeSplit = UTCDateSplit[4].split(':');

  let a = +UTCTimeSplit[0] - (new Date().getTimezoneOffset() / 60);
  if (a > 23) {
    // 24 -> 00, 25 -> 1
    a = Math.abs(24-a);
  } else if (a < 0) {
    a = 24 + a;
  }
  UTCTimeSplit[0] = dd(a);
  return UTCTimeSplit.join(':');
}

const Text = styled.div({
  fontSize: 10,
  fontWeight: 500,
  color: '#DDD',
})

const StockholmClock = () => {
  const [time, setTime] = useState(() => correctTimezone(Date.now()));
  const [timer, setTimer] = useState<NonDriftingInterval>();

  useEffect(() => {
    const t = new NonDriftingInterval(() => setTime(correctTimezone(Date.now())), 1000);
    t.start();
    setTimer(t);
    return () => { if (timer) {
      timer.stop()
    }}
  }, []);

  return <Text>{time}</Text>
}

export default StockholmClock;
