const sameIdentifier = (text: string, identifier: string, generator: (active: string) => string) => {
  let sp = text.split(identifier);
  for (let i = 1; i < sp.length; i += 2) {
    sp[i] = generator(sp[i]);
  }
  return sp.join('');
}

const parseHunter = (text: string, begin: string, end: string, generator: (active: string) => string) => {
  if (begin === end) {
    return sameIdentifier(text, begin, generator);
  }
  let sp = text.split(begin);
  sp.splice(0, 1);
  let hits = sp.map((x) => x.substring(0, x.indexOf(end)));
  hits.forEach((hit) => {
    text = text.replace(`${begin}${hit}${end}`, generator(hit))
  })
  return text;
}

const codeTagGenerator = (code: string) => `<code>${code}</code>`
const linkTagGenerator = (link: string) => {
  let name = link.split('//')[1];
  if (link[0] === '[' && link.indexOf(']') > 0) {
    name = link.substring(1, link.indexOf(']'));
    link = link.substring(link.indexOf(']') + 1);
  }
  return `<a href="${link}" target="_blank" rel="noopener noreferrer">${name}</a>`;
}

const txt2HTML = (text: string) => {
  text = parseHunter(text, '~`', '`~', codeTagGenerator);
  text = parseHunter(text, '~[', ']~', linkTagGenerator);

  return text;
}

export default txt2HTML;
