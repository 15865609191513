class NonDriftingInterval {
  task: () => void;
  startTime: number;
  interval: number;
  timer: NodeJS.Timeout | null = null;

  constructor(task: () => void, interval: number) {
    this.task = task;
    this.interval = interval;
    this.startTime = Date.now();
  }

  start() {
    this.startTime = Date.now();
    this.run();
  }

  run() {
    this.timer = setTimeout(() => {
      this.task();
      this.run();
    }, this.interval - Math.abs((Date.now() % 1000) - (this.startTime % 1000)));
  }

  stop() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }
}

export default NonDriftingInterval;
