import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {txt2HTML} from "../../../../helpers";
import {animated, useSpring} from "react-spring";

interface Props {
  ts: number;
  title: string;
  txt: string;
}

const Container = styled.div({
  marginTop: 16
});

const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 8px;
  height: 50px;

  &:hover {
    cursor: pointer
  }
  &:active {
    opacity: 75%;
  }
`;

const Time = styled.div({
  fontSize: 12,
  color: '#999',
  fontWeight: 500,
});

const Title = styled.div({
  fontSize: 20,
  color: '#fff',
  fontWeight: 600,
  maxHeight: 30,
  overflow: 'hidden'
});

const Text = styled.div({
  whiteSpace: "pre-line",
  fontSize: 14,
  color: '#F2F2F2'
});

const formatDate = (date: Date) => {
  const dd = (n: number) => n > 9 ? n : `0${n}`;
  const dt = `${dd(date.getDate())}/${dd(date.getMonth()+1)}/${date.getFullYear().toString().slice(2)}`;
  const t = `${dd(date.getHours())}:${dd(date.getMinutes())}`

  return `${dt} ${t}`
}

const Thought: React.FC<Props> = ({ts, title, txt}) => {
  const ref = useRef(null);
  const time = useMemo(() => formatDate(new Date(+ts)), [ts]);
  const text = useMemo(() => txt2HTML(txt), [txt]);
  const [isOpen, setIsOpen] = useState(false);
  const [style, animate] = useSpring(() => ({ height: "0px" }), []);

  useEffect(() => {
    animate({
      config: { tension: 175 },
      // @ts-ignore
      height: (isOpen ? ref.current.offsetHeight : 0) + "px",
    });
  }, [animate, isOpen]);

  const open = useCallback(() => {
    animate.start();
    setIsOpen(!isOpen)
  }, [animate, isOpen]);

  return (
    <Container>
      <TopBar onClick={open}>
        <Time>{time}</Time>
        <Title>{title}</Title>
      </TopBar>
      <animated.div
        style={{
          overflow: "hidden",
          width: "100%",
          ...style
        }}
      >
        <Text ref={ref} dangerouslySetInnerHTML={{__html: text}}/>
      </animated.div>
    </Container>
);
}

export default Thought;
