import React, {useState, useCallback} from 'react';
import styled from "styled-components";

export interface IProject {
  state: "halted" | "active" | "finished" | "ideation";
  name: string;
  short_description: string;
}

interface Props {
  project: IProject
}

const stateColors = {
  "halted": "#ff033e",
  "ideation": "#af69ef",
  "active": "#00ff80",
  "finished": "#318ce7",
}

const ProjectItem: React.FC<Props> = ({project}) => {
  return (
    <Container>
      <TitleContainer>
        <Title>{project.name}</Title>
        <Subtitle>STATUS: <span style={{color: stateColors[project.state]}}>{project.state.toUpperCase()}</span></Subtitle>
      </TitleContainer>
      <DescriptionContainer>
        <DescriptionText>
          {project.short_description}
        </DescriptionText>
      </DescriptionContainer>
    </Container>
  );
}

const Container = styled.div({
  // marginTop: 16,
  width: '100%',
  flexDirection: "row",
  display: "flex",
});

const TitleContainer = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const DescriptionContainer = styled.div({
  flex: 3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right'
})

const Title = styled.div({
  fontSize: 20,
  color: '#fff',
  fontWeight: 600,
  // maxHeight: 30,
  overflow: 'hidden'
});

const Subtitle = styled.div({
  fontSize: 12,
  color: '#bbb',
  fontWeight: 700,
  marginTop: 4,
});

const DescriptionText = styled.div({
  fontSize: 10,
  color: '#eee',
  textAlign: 'right',
  fontWeight: 500,
})

export default ProjectItem;